<template>
    <div>

      <div class="center mb">
        <img src="../../assets/images/art752.png">

        <div class="title1">{{$t("welcome1.title1")}}</div>
        <div class="title2">{{$t("welcome1.title2")}}</div>
      </div>

      <div class="text1 mb">{{$t("welcome1.content1")}}</div>
      <div class="text1 mb">{{$t("welcome1.content2")}}</div>

      <ui-button class="btn">{{$t('welcome1.i_m_interested')}}</ui-button>

    </div>
</template>

<script>
  import UiButton from '@/components/ui/UiButton';

  export default {
    name: 'welcome1',
    components: { UiButton }
  }
</script>

<style scoped>
.btn {
  margin-top: 100px;
}
</style>
